import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useWorkticketView } from "contexts/workticketViewContext";
import useStyles from "./styles";
import ZeroState from "components/common/LunaLens/zeroState";
import DialogLunaLens from "components/common/LunaLens/dialog/dialogLunaLens";
import HistoryAnalysisDetail from "components/common/LunaLens/historyAnalysisDetail";
import NewAnalysisDetail from "components/common/LunaLens/newAnalysisDetail";
import LunaLensButton from "components/common/LunaLens/lunaLensButton";
import DialogDelete from "components/common/LunaLens/dialog/dialogDelete";
import useLunaLensStore from "store/lunaLensAnalysis";
import { deleteAnalysis, getAnalysisList } from "services/lunaLensService";
import AnalysisStatusResumeList from "components/common/LunaLens/analysisStatusResumeList";
import {
  usePageHeaderDispatch,
  usePageHeaderState,
} from "contexts/pageHeaderContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";

const WorkticketLunaLens = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [stateContext] = useWorkticketView();
  const [analysisCardSelected, setAnalysisCardSelected] = useState({});
  const [userId, setUserId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pageHeaderTabs } = usePageHeaderState();
  const dispatchPageHeader = usePageHeaderDispatch();

  const { history } = props;

  const setWorkticketAnalysisList = useLunaLensStore(
    (state) => state.setWorkticketAnalysisList
  );

  const setAnalysisStarted = useLunaLensStore(
    (state) => state.setAnalysisStarted
  );

  const analysisStarted = useLunaLensStore((state) => state.analysisStarted);
  const setAnalysisComment = useLunaLensStore(
    (state) => state.setAnalysisComment
  );

  useEffect(() => {
    const fetchLensData = async () => {
      if (props.workTicketId) {
        setIsLoading(true);
        const data = await getAnalysisList({
          workticketId: props.workTicketId,
        });
        const user = JSON.parse(localStorage.getItem("user"));
        setUserId(user.id);
        const filteredList = data.data.analysis.filter(
          (item) =>
            parseInt(item.user_id) === user.id ||
            (parseInt(item.user_id) !== user.id && item.status === 1) ||
            hasPermission(permissionWorkticket.LUNA_LENS_MANAGE, permissions)
        );
        setWorkticketAnalysisList(filteredList);
        setIsLoading(false);
      }
    };
    fetchLensData();
  }, [props.workTicketId, setWorkticketAnalysisList, permissions]);

  useEffect(() => {
    const pingEndpoint = async () => {
      if (analysisStarted) {
        const data = await getAnalysisList({
          workticketId: props.workTicketId,
        });
        const user = JSON.parse(localStorage.getItem("user"));
        const filteredList = data.data.analysis.filter(
          (item) =>
            parseInt(item.user_id) === user.id ||
            (parseInt(item.user_id) !== user.id && item.status === 1) ||
            hasPermission(permissionWorkticket.LUNA_LENS_MANAGE, permissions)
        );
        setWorkticketAnalysisList(filteredList);
        const pendingAnalysis = data.data.analysis.filter(
          (analysis) => analysis.status === 0
        );
        if (pendingAnalysis.length === 0) {
          setAnalysisStarted(false);
        }
      }
    };

    pingEndpoint();
    const intervalId = setInterval(pingEndpoint, 30000); // Ping every 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [
    props.workTicketId,
    setWorkticketAnalysisList,
    setAnalysisStarted,
    analysisStarted,
    permissions,
  ]);

  const workticketAnalysisList = useLunaLensStore(
    (state) => state.workticketAnalysisList
  );
  const deleteWorkticketAnalysisList = useLunaLensStore(
    (state) => state.deleteWorkticketAnalysisList
  );

  const { isLoading: isLoadingWorkticket } = stateContext ?? null;

  const handleHistoryOptionClick = (selectedHistoryData) => {
    if (selectedHistoryData === "VIEW_REPORT") {
      setAnalysisComment(false);
      redirectTab(analysisCardSelected);
    } else if (selectedHistoryData === "EDIT_REPORT") {
      setAnalysisComment(false);
      setOpenEdit(true);
    } else if (selectedHistoryData === "ADD_COMMENT") {
      setAnalysisComment(true);
      setOpenEdit(true);
    } else if (selectedHistoryData === "PRINT_PDF") {
      setAnalysisComment(false);
      console.log("print pdf");
    } else if (selectedHistoryData === "DELETE_ANALYSIS") {
      setAnalysisComment(false);
      setOpenDelete(true);
    }
  };

  const redirectTab = (data) => {
    const tabId = new Date().valueOf();
    const redirectTab = `/lens-analysis/${data.workticketId}/${
      data.workticketNumber
    }/${data.analysisId}/${"new"}`;
    pageHeaderTabs.push({
      id: tabId,
      label: `${data.workticketNumber}`,
      link: redirectTab,
      hasClose: true,
      parent: true,
    });
    dispatchPageHeader({
      type: "SET_PAGE_TAB",
      pageHeaderTabs: [...pageHeaderTabs],
    });
    dispatchPageHeader({ type: "SET_CURRENT_TAB", currentTab: tabId });
    history.push(redirectTab);
  };

  const handleCardClick = (data) => {
    setAnalysisCardSelected({
      analysisId: data.id,
      responseText: data.response_text,
      workticketId: data.workticket_id,
      workticketNumber: data.workticket_number,
    });
  };

  const handleDeleteAnalysis = async () => {
    await deleteAnalysis(analysisCardSelected.analysisId);
    deleteWorkticketAnalysisList(analysisCardSelected.analysisId);
  };

  if (isLoading || isLoadingWorkticket) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <>
      <Box className={classes.zeroStateHeaderContainer}>
        <Typography variant="h3">Insights</Typography>
        <LunaLensButton onClick={() => setOpenNew(true)} />
      </Box>
      <Box className={classes.containerBodyLunaLens}>
        {workticketAnalysisList.length > 0 ? (
          <AnalysisStatusResumeList
            analysis={workticketAnalysisList}
            onCardClick={handleCardClick}
            onHistoryOptionClick={handleHistoryOptionClick}
            userId={userId}
          />
        ) : (
          <ZeroState />
        )}
        <DialogLunaLens
          open={openEdit}
          setOpen={setOpenEdit}
          analysis="edit"
          content={
            <HistoryAnalysisDetail
              closeLensDialog={() => setOpenEdit(false)}
              analysis={analysisCardSelected}
              workticketId={props.workTicketId}
              workticketNumber={props.workTicketNumber}
            />
          }
          workticketId={props.workTicketId}
          workticketNumber={props.workTicketNumber}
          analysisEditId={analysisCardSelected.analysisId}
        />
        <DialogLunaLens
          open={openNew}
          setOpen={setOpenNew}
          analysis="new"
          content={
            <NewAnalysisDetail
              closeLensDialog={() => setOpenNew(false)}
              workticketId={props.workTicketId}
              workticketNumber={props.workTicketNumber}
              location={props.workTicketLocation}
              description={props.workTicketDescription}
              summary={props.workTicketSummary}
              customer={props.workTicketCustomer}
            />
          }
          workticketId={props.workTicketId}
          workticketNumber={props.workTicketNumber}
        />
        <DialogDelete
          title="Delete Analysis"
          description="Are you sure you want to delete this analysis?"
          successDescription="Analysis has been deleted successfully."
          open={openDelete}
          handleCancel={() => setOpenDelete(false)}
          handleConfirm={handleDeleteAnalysis}
        />
      </Box>
    </>
  );
};

export default withRouter(WorkticketLunaLens);
