import React, { useState, useEffect, useContext, useMemo } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import VisualInspectionIcon from "assets/icons/visualInspectionsIcon";
import ServiceIcon from "assets/icons/serviceIcon";

import {
  getFilterJobs,
  getFilterCustomers,
  getFilterProjects,
  getFilterZips,
  getFilterWorkticketExternal,
  getFilterCities,
  getFilterManagers,
  getFilterDirectors,
  getFilterServiceCategories,
  convertUriFilterData,
  convertUriFilterDateData,
} from "components/util/filterUtil";

import { logException } from "components/util/logUtil";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState } from "contexts/tableContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import {
  permissionProject,
  permissionWorkticket,
  hasPermission,
} from "lib/permissions";

import { getWorknovaWtList } from "services/worknovaService";
import {
  countryStates,
  externalTypes,
  workticketsCancellationReason,
  worknovaTypes,
  sourceTypes,
  workTicketTypes,
  levelCompliance,
  completionMethod,
} from "constants.js";
import useStyles from "components/common/Filters/styles";

const addOnFilters = {
  0: [
    { value: "only_unassigned", label: "Only Not Assigned" },
    { value: "only_not_schedule", label: "Only Not Scheduled" },
  ],
  1: [
    { value: "only_unassigned", label: "Only Not Assigned" },
    { value: "only_not_schedule", label: "Only Not Scheduled" },
  ],
  2: [
    { value: "only_invoiced", label: "Invoiced" },
    { value: "only_not_invoiced", label: "Not Invoiced" },
    { value: "include_archived", label: "Include Archived" },
  ],
  40: [
    { value: "include_done", label: "Include Verified" },
    { value: "include_archived", label: "Include Archived" },
    {
      value: "include_only_work_completed",
      label: "Only Work Completed",
    },
  ],
  70: [{ value: "only_cancelled", label: "Only Canceled" }],
  80: [
    { value: "only_immediate_1", label: "Overdue" },
    { value: "only_immediate_2", label: "Corrective Action" },
    { value: "only_immediate_3", label: "Emergency" },
    { value: "only_immediate_4", label: "Recalled" },
    { value: "only_immediate_5", label: "Escalated" },
  ],
};

const symbolLabel = {
  less: "<",
  less_equal: "<=",
  equal: "=",
  not_equal: "!=",
  greater: ">",
  greater_equal: ">=",
};

const WorkticketFilters = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { currentTab } = useTableState();
  const {
    users,
    permissions,
    filtersJobs,
    filtersZips,
    filtersCities,
    filtersCustomers,
    filtersProjects,
  } = globalUi;
  const [subStatusFilters, setSubStatusFilters] = useState([]);
  const [jobFilters, setJobFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [projectFilters, setProjectFilters] = useState([]);
  const [assignedFilters, setAssignedFilters] = useState([]);
  const [managedFilters, setManagedFilters] = useState([]);
  const [stateFilters, setStateFilters] = useState([]);
  const [woFilesFilters, setWoFilesFilters] = useState([]);
  const [woTypesFilters, setWoTypesFilters] = useState([]);
  const [woSourcesFilters, setWoSourcesFilters] = useState([]);
  const [zipFilters, setZipFilters] = useState([]);
  const [cityFilters, setCityFilters] = useState([]);
  const [sitesFilters, setSitesFilters] = useState([]);
  const [directorFilters, setDirectorFilters] = useState([]);
  // const [seniorDirectorFilters, setSeniorDirectorFilters] = useState([]);
  const [workTicketTypeFilter, setWorkTicketTypeFilter] = useState([]);
  const [managerFilters, setManagerFilters] = useState([]);
  const [serviceCategoriesFilters, setServiceCategoriesFilters] = useState([]);
  const [externalCategoryFilters, setExternalCategoryFilters] = useState([]);
  const [externalPriorityFilters, setExternalPriorityFilters] = useState([]);
  const [externalStatusFilters, setExternalStatusFilters] = useState([]);
  const [externalTypeFilters, setExternalTypeFilters] = useState([]);
  const [cancellationReasonFilters, setCancellationReasonFilters] = useState(
    []
  );
  const [levelComplianceFilters, setLevelComplianceFilters] = useState([]);
  const [completionMethodFilters, setCompletionMethodFilters] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [sites, setSites] = useState([]);
  const [customers, setCustomers] = useState(null);
  const [projects, setProjects] = useState(null);
  const [cities, setCities] = useState(null);
  const [woFiles, setWoFiles] = useState([]);
  const [zips, setZips] = useState([]);
  const [managers, setManagers] = useState(null);
  const [directors, setDirectors] = useState(null);
  const [serviceCategories, setServiceCategories] = useState(null);
  const [includeChild] = useState(true);
  // const [seniorDirectors, setSeniorDirectors] = useState(null);
  const [externalCategories, setExternalCategories] = useState([]);
  const [externalPriorities, setExternalPriorities] = useState([]);
  const [externalStatuses, setExternalStatuses] = useState([]);
  const [loadData1, setLoadData1] = useState(false);
  const [loadData2, setLoadData2] = useState(false);
  const [loadData3, setLoadData3] = useState(false);
  const [loadData4, setLoadData4] = useState(false);
  const [loadData5, setLoadData5] = useState(false);
  const [loadData6, setLoadData6] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters, isLoadingFiltersUri } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { removeJobs, removeCustomers, removeProjects, isPartner } = props;

  const usersList = useMemo(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    return [{ id: user.id, first_name: "Me", last_name: "" }, ...users];
  }, [users]);

  useEffect(() => {
    if (
      isLoadingFiltersUri &&
      loadData1 &&
      loadData2 &&
      loadData3 &&
      loadData4 &&
      loadData5 &&
      loadData6
    ) {
      const searchParam = props.history?.location?.search;
      const query = new URLSearchParams(searchParam);
      const filtersSearch = [
        "sub_status",
        "customer",
        "state",
        "zip",
        "city",
        "job_site_number",
        "site_id",
        "external_category",
        "external_priority",
        "external_status",
        "external_type",
        "project",
        "managed",
        "manager",
        "director",
        "senior_director",
        "assigned",
        "job",
        "job_number",
        "worknova",
        "worknova_types",
        "sources",
        "cancellation_reason",
        "service_category",
        "revenue",
        "revenue_symbol",
        "cost",
        "cost_symbol",
        "margin",
        "margin_symbol",
        "workticket_type",
        "level_compliance",
        "completion_method",
      ];
      const resultFilter = convertUriFilterData(query, filtersSearch);
      if (resultFilter && resultFilter.length > 0) {
        let filterData = [];
        resultFilter.forEach((element) => {
          if (element.filter === "sub_status") {
            const subStatusFiltersSelected = addOnFilters[currentTab].filter(
              (item) => element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "sub_status",
              group: "Sub Status",
              values: [...subStatusFiltersSelected],
            });
          }

          if (element.filter === "cancellation_reason") {
            const cancellationFiltersSelected =
              workticketsCancellationReason.filter((item) =>
                element.values.includes(item.value.toString())
              );
            filterData.push({
              filter: "cancellation_reason",
              group: "Cancellation Reason",
              values: [...cancellationFiltersSelected],
            });
          }

          if (element.filter === "project") {
            const projectsFiltersSelected = projects.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "project",
              group: "Project",
              values: [...projectsFiltersSelected],
            });
          }

          if (element.filter === "customer") {
            const customerFiltersSelected = customers.filter((cItem) =>
              element.values.includes(cItem.value.toString())
            );
            filterData.push({
              filter: "customer",
              group: "Customer",
              values: [...customerFiltersSelected],
            });
          }

          if (element.filter === "job") {
            const jobFiltersSelected = jobs.filter((jItem) =>
              element.values.includes(jItem.id.toString())
            );

            const jobFilterConvert = jobFiltersSelected.map((job) => {
              return {
                label: `${job.job_number} - ${job.job_description}`,
                value: job.id,
                id: job.id,
                job_type: job?.job_type,
                parent_job_id: job?.parent_job_id,
              };
            });
            filterData.push({
              filter: "job",
              group: "Job",
              values: [...jobFilterConvert],
            });
          }

          if (element.filter === "job_number") {
            const jobFiltersSelected = jobs.filter((jItem) =>
              element.values.includes(jItem.job_number.toString())
            );
            const jobFilterConvert = jobFiltersSelected.map((job) => {
              return {
                label: `${job.job_number} - ${job.job_description}`,
                value: job.id,
                id: job.id,
                job_type: job?.job_type,
                parent_job_id: job?.parent_job_id,
              };
            });
            filterData.push({
              filter: "job",
              group: "Job",
              values: [...jobFilterConvert],
            });
          }

          if (element.filter === "state") {
            const statesFiltersSelected = countryStates.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "state",
              group: "State",
              values: [...statesFiltersSelected],
            });
          }

          if (element.filter === "zip") {
            const zipsFiltersSelected = zips.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "zip",
              group: "Zip",
              values: [...zipsFiltersSelected],
            });
          }

          if (element.filter === "city") {
            const citiesFiltersSelected = cities.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "city",
              group: "City",
              values: [...citiesFiltersSelected],
            });
          }

          if (element.filter === "manager") {
            const managersFiltersSelected = managers.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "manager",
              group: "Manager",
              values: [...managersFiltersSelected],
            });
          }

          if (element.filter === "director") {
            const directorsFiltersSelected = directors.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "director",
              group: "Director",
              values: [...directorsFiltersSelected],
            });
          }
          if (element.filter === "service_category") {
            const serviceFiltersSelected = serviceCategories.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "service_category",
              group: "Service Category",
              values: [...serviceFiltersSelected],
            });
          }

          if (element.filter === "external_category") {
            const externalFiltersSelected = externalCategories.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "external_category",
              group: "Category",
              values: [...externalFiltersSelected],
            });
          }

          if (element.filter === "external_priority") {
            const externalFiltersSelected = externalPriorities.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "external_priority",
              group: "Priority",
              values: [...externalFiltersSelected],
            });
          }

          if (element.filter === "external_status") {
            const externalFiltersSelected = externalStatuses.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "external_status",
              group: "Status",
              values: [...externalFiltersSelected],
            });
          }

          if (element.filter === "external_type") {
            const externalFiltersSelected = externalTypes.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "external_type",
              group: "Type",
              values: [...externalFiltersSelected],
            });
          }

          if (element.filter === "sources") {
            const externalFiltersSelected = sourceTypes.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "sources",
              group: "Source",
              values: [...externalFiltersSelected],
            });
          }

          if (element.filter === "level_compliance") {
            const levelComplianceFiltersSelected = levelCompliance.filter(
              (item) => element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "level_compliance",
              group: "Level Compliance",
              values: [...levelComplianceFiltersSelected],
            });
          }

          if (element.filter === "completion_method") {
            const completionMethodFiltersSelected = completionMethod.filter(
              (item) => element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "completion_method",
              group: "Completion Method",
              values: [...completionMethodFiltersSelected],
            });
          }

          if (
            element.filter === "job_site_number" ||
            element.filter === "site_id"
          ) {
            const siteFiltersSelected = sites.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "job_site_number",
              group: "Site",
              values: [...siteFiltersSelected],
            });
          }

          if (element.filter === "worknova") {
            const worknovaFiltersSelected = woFiles.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "worknova",
              group: "Workorders File",
              values: [...worknovaFiltersSelected],
            });
          }

          if (element.filter === "worknova_types") {
            const worknovaFiltersSelected = worknovaTypes.filter((item) =>
              element.values.includes(item.value.toString())
            );
            filterData.push({
              filter: "worknova_types",
              group: "Workorders Types",
              values: [...worknovaFiltersSelected],
            });
          }

          if (element.filter === "revenue") {
            const symbolRevenue = resultFilter.find(
              (item) => item.filter === "revenue_symbol"
            );
            const symbolDisplay =
              symbolLabel[symbolRevenue.values[0]] ?? symbolRevenue.values[0];

            filterData.push({
              filter: "revenue",
              group: "Revenue",
              values: [
                {
                  value: element.values[0],
                  label: `${symbolDisplay} ${element.values[0]}`,
                },
              ],
              depended: ["revenue_symbol"],
            });
          }

          if (element.filter === "revenue_symbol") {
            filterData.push({
              filter: "revenue_symbol",
              group: "Revenue Symbol",
              values: [{ value: element.values[0], label: element.values[0] }],
              hidden: true,
            });
          }

          if (element.filter === "cost") {
            const symbolCost = resultFilter.find(
              (item) => item.filter === "cost_symbol"
            );
            const symbolDisplay =
              symbolLabel[symbolCost.values[0]] ?? symbolCost.values[0];

            filterData.push({
              filter: "cost",
              group: "Cost",
              values: [
                {
                  value: element.values[0],
                  label: `${symbolDisplay} ${element.values[0]}`,
                },
              ],
              depended: ["cost_symbol"],
            });
          }

          if (element.filter === "cost_symbol") {
            filterData.push({
              filter: "cost_symbol",
              group: "Cost Symbol",
              values: [{ value: element.values[0], label: element.values[0] }],
              hidden: true,
            });
          }

          if (element.filter === "margin") {
            const symbolMargin = resultFilter.find(
              (item) => item.filter === "margin_symbol"
            );
            const symbolDisplay =
              symbolLabel[symbolMargin.values[0]] ?? symbolMargin.values[0];

            filterData.push({
              filter: "margin",
              group: "Margin",
              values: [
                {
                  value: element.values[0],
                  label: `${symbolDisplay} ${element.values[0]} %`,
                },
              ],
              depended: ["margin_symbol"],
            });
          }

          if (element.filter === "margin_symbol") {
            filterData.push({
              filter: "margin_symbol",
              group: "Margin Symbol",
              values: [{ value: element.values[0], label: element.values[0] }],
              hidden: true,
            });
          }

          if (users.length) {
            if (element.filter === "managed") {
              const managedFiltersSelected = users.filter((item) =>
                element.values.includes(item.id.toString())
              );

              const managedFilterConvert = managedFiltersSelected.map(
                (managed) => {
                  return {
                    label: `${managed.first_name} ${managed.last_name}`,
                    value: managed.id,
                  };
                }
              );

              filterData.push({
                filter: "managed",
                group: "Managed",
                values: [...managedFilterConvert],
              });
            }

            if (element.filter === "assigned") {
              const assignedFiltersSelected = users.filter((item) =>
                element.values.includes(item.id.toString())
              );

              const assignedFilterConvert = assignedFiltersSelected.map(
                (assigned) => {
                  return {
                    label: `${assigned.first_name} ${assigned.last_name}`,
                    value: assigned.id,
                  };
                }
              );

              filterData.push({
                filter: "assigned",
                group: "Assigned To",
                values: [...assignedFilterConvert],
              });
            }
            if (element.filter === "workticket_type") {
              filterData.push({
                filter: "workticket_type",
                group: "Workticket Type",
                values: [...workTicketTypeFilter],
              });
            }
          }
        });
        dispatchFilters({
          type: "SET_FILTERS_CORE",
          filters: filterData,
        });

        localStorage.setItem(
          `filter_${window.location.pathname.split("/")[1]}`,
          JSON.stringify(filterData)
        );
      }
      const resultFilterDate = convertUriFilterDateData(query);

      if (resultFilterDate) {
        dispatchFilters({
          type: "SET_DATE",
          ...resultFilterDate,
        });
        localStorage.setItem(
          `filter_date_${window.location.pathname.split("/")[1]}`,
          JSON.stringify(resultFilterDate)
        );
      }

      dispatchFilters({
        type: "SET_FILTERS_LOADING_URI",
        isLoading: false,
      });

      dispatchFilters({
        type: "SET_LOADING",
        isLoadingFilters: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingFiltersUri,
    loadData1,
    loadData2,
    loadData3,
    loadData4,
    loadData5,
    loadData6,
  ]);

  useEffect(() => {
    const loadJob = async () => {
      try {
        if (!filtersJobs && !filtersZips && !filtersCities) {
          const [
            resultForRender,
            resultZips,
            resultCities,
            resultServiceCategories,
          ] = await Promise.all([
            getFilterJobs(),
            getFilterZips(),
            getFilterCities(),
            getFilterServiceCategories(),
          ]);
          setJobs(resultForRender);
          setZips(resultZips);
          setCities(resultCities);
          setServiceCategories(resultServiceCategories);
          dispatchGlobalUi({
            type: "SET_FILTERS_LOCATIONS",
            filtersJobs: resultForRender,
            filtersZips: resultZips,
            filtersCities: resultCities,
          });
          const resultForRenderSite = resultForRender
            .filter(
              (item) => item.site_number !== "" && item.site_number !== null
            )
            .map((item) => ({
              value: item.id,
              label: `${item.job_description.substring(0, 3).toUpperCase()} - ${
                item.site_number
              }`,
            }));
          setSites(resultForRenderSite);
        } else {
          setJobs(filtersJobs);
          setZips(filtersZips);
          setCities(filtersCities);
          const resultForRenderSite = filtersJobs
            .filter(
              (item) => item.site_number !== "" && item.site_number !== null
            )
            .map((item) => ({
              value: item.id,
              label: `${item.job_description.substring(0, 3).toUpperCase()} - ${
                item.site_number
              }`,
            }));
          setSites(resultForRenderSite);
        }
        setLoadData1(true);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length && !removeJobs) {
      loadJob();
    } else {
      setLoadData1(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, removeJobs]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        if (!filtersCustomers) {
          const resultForRender = await getFilterCustomers();
          setCustomers(resultForRender);
          dispatchGlobalUi({
            type: "SET_FILTERS_CUSTOMERS",
            filtersCustomers: resultForRender,
          });
        } else {
          setCustomers(filtersCustomers);
        }
        setLoadData2(true);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (!customers) {
      loadCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    const loadExternalFilters = async () => {
      try {
        const resultExternal = await getFilterWorkticketExternal();
        setExternalCategories(resultExternal?.categories ?? []);
        setExternalPriorities(resultExternal?.priorities ?? []);
        setExternalStatuses(resultExternal?.statuses ?? []);
        setLoadData3(true);
      } catch (e) {
        logException(e, "Cannot load external filter data");
      }
    };

    if (
      !externalCategories.length &&
      hasPermission(permissionWorkticket.WALGREENS_DATA, permissions)
    ) {
      loadExternalFilters();
    } else {
      setLoadData3(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalCategories, permissions]);

  useEffect(() => {
    const loadWoFiles = async () => {
      try {
        const worknovaListResult = await getWorknovaWtList();
        const renderWoFilesList =
          worknovaListResult.data.data.upload_records.map((wo) => {
            return {
              value: wo.id,
              label: `${wo.id} - ${moment(wo.created_at).format("L")}`,
            };
          });

        setWoFiles(renderWoFilesList);
        setLoadData4(true);
      } catch (e) {
        logException(e, "Cannot load workorder file data");
      }
    };
    if (
      !woFiles.length &&
      hasPermission(permissionWorkticket.WORKTICKET_WOID, permissions)
    ) {
      loadWoFiles();
    } else {
      setLoadData4(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [woFiles, permissions]);

  useEffect(() => {
    const loadProject = async () => {
      try {
        if (!filtersProjects) {
          const resultForRender = await getFilterProjects();
          setProjects(resultForRender);
          dispatchGlobalUi({
            type: "SET_FILTERS_PROJECTS",
            filtersProjects: resultForRender,
          });
        } else {
          setProjects(filtersProjects);
        }
        setLoadData5(true);
      } catch (e) {
        logException(e, "Cannot load project data");
      }
    };
    if (!projects && !removeProjects) {
      loadProject();
    } else {
      setLoadData5(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, removeProjects]);

  useEffect(() => {
    const loadDirectorsManagersFilters = async () => {
      try {
        const [resultManagers, resultDirectors] = await Promise.all([
          getFilterManagers(),
          getFilterDirectors(),
        ]);

        setManagers(resultManagers);
        setDirectors(resultDirectors);
        setLoadData6(true);
      } catch (e) {
        logException(e, "Cannot load directors and managers data");
      }
    };

    if (!managers) {
      loadDirectorsManagersFilters();
    } else {
      setLoadData6(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managers, permissions]);

  useEffect(() => {
    if (anchorEl) {
      if (jobs.length) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "job"
        );
        if (selectedJobFilter) {
          const selectedValue = selectedJobFilter.values.map(
            (job) => job.value
          );
          const findJob = jobs.filter((job) => selectedValue.includes(job.id));
          setJobFilters(findJob);
        } else {
          setJobFilters([]);
        }
      }
      if (projects?.length) {
        const selectedProjectsFilter = filters.find(
          (filter) => filter.filter === "project"
        );

        setProjectFilters(
          selectedProjectsFilter ? selectedProjectsFilter.values : []
        );
      }

      if (addOnFilters[currentTab]) {
        const selectedSubStatusFilter = filters.find(
          (filter) => filter.filter === "sub_status"
        );
        if (selectedSubStatusFilter) {
          setSubStatusFilters(selectedSubStatusFilter.values);
        } else {
          setSubStatusFilters([]);
        }
      }

      if (workticketsCancellationReason) {
        const selectedCancellationFilter = filters.find(
          (filter) => filter.filter === "cancellation_reason"
        );
        if (selectedCancellationFilter) {
          setCancellationReasonFilters(selectedCancellationFilter.values);
        } else {
          setCancellationReasonFilters([]);
        }
      }

      if (directors?.length) {
        const selectedDirectorsFilter = filters.find(
          (filter) => filter.filter === "director"
        );
        if (selectedDirectorsFilter) {
          setDirectorFilters(selectedDirectorsFilter.values);
        } else {
          setDirectorFilters([]);
        }
      }
      if (managers?.length) {
        const selectedManagersFilter = filters.find(
          (filter) => filter.filter === "manager"
        );
        if (selectedManagersFilter) {
          setManagerFilters(selectedManagersFilter.values);
        } else {
          setManagerFilters([]);
        }
      }

      if (customers?.length) {
        const selectedCustomersFilter = filters.find(
          (filter) => filter.filter === "customer"
        );
        if (selectedCustomersFilter) {
          setCustomerFilters(selectedCustomersFilter.values);
        } else {
          setCustomerFilters([]);
        }
      }
      if (countryStates?.length) {
        const selectedStateFilter = filters.find(
          (filter) => filter.filter === "state"
        );
        setStateFilters(selectedStateFilter ? selectedStateFilter.values : []);
      }

      if (zips?.length) {
        const selectedZipFilter = filters.find(
          (filter) => filter.filter === "zip"
        );
        setZipFilters(selectedZipFilter ? selectedZipFilter.values : []);
      }

      if (cities?.length) {
        const selectedCityFilter = filters.find(
          (filter) => filter.filter === "city"
        );
        setCityFilters(selectedCityFilter ? selectedCityFilter.values : []);
      }

      if (sites?.length) {
        const selectedSitesFilter = filters.find(
          (filter) => filter.filter === "job_site_number"
        );
        setSitesFilters(selectedSitesFilter ? selectedSitesFilter.values : []);
      }

      if (serviceCategories?.length) {
        const selectedServiceCategoryFilter = filters.find(
          (filter) => filter.filter === "service_category"
        );
        setServiceCategoriesFilters(
          selectedServiceCategoryFilter
            ? selectedServiceCategoryFilter.values
            : []
        );
      }

      if (externalCategories?.length) {
        const selectedExternalCategoryFilter = filters.find(
          (filter) => filter.filter === "external_category"
        );
        setExternalCategoryFilters(
          selectedExternalCategoryFilter
            ? selectedExternalCategoryFilter.values
            : []
        );
      }

      if (externalPriorities?.length) {
        const selectedExternalPriorityFilter = filters.find(
          (filter) => filter.filter === "external_priority"
        );
        setExternalPriorityFilters(
          selectedExternalPriorityFilter
            ? selectedExternalPriorityFilter.values
            : []
        );
      }

      if (externalStatuses?.length) {
        const selectedExternalStatusFilter = filters.find(
          (filter) => filter.filter === "external_status"
        );
        setExternalStatusFilters(
          selectedExternalStatusFilter
            ? selectedExternalStatusFilter.values
            : []
        );
      }

      if (externalTypes?.length) {
        const selectedExternalTypeFilter = filters.find(
          (filter) => filter.filter === "external_type"
        );
        setExternalTypeFilters(
          selectedExternalTypeFilter ? selectedExternalTypeFilter.values : []
        );
      }

      if (sourceTypes?.length) {
        const selectedWoSourcesFilter = filters.find(
          (filter) => filter.filter === "sources"
        );
        setWoSourcesFilters(
          selectedWoSourcesFilter ? selectedWoSourcesFilter.values : []
        );
      }

      if (levelCompliance?.length) {
        const selectedLevelComplianceFilter = filters.find(
          (filter) => filter.filter === "level_compliance"
        );
        setLevelComplianceFilters(
          selectedLevelComplianceFilter
            ? selectedLevelComplianceFilter.values
            : []
        );
      }

      if (completionMethod?.length) {
        const selectedCompletionMethodFilter = filters.find(
          (filter) => filter.filter === "completion_method"
        );
        setCompletionMethodFilters(
          selectedCompletionMethodFilter
            ? selectedCompletionMethodFilter.values
            : []
        );
      }

      if (woFiles?.length) {
        const selectedWoFilesFilter = filters.find(
          (filter) => filter.filter === "worknova"
        );

        setWoFilesFilters(
          selectedWoFilesFilter ? selectedWoFilesFilter.values : []
        );
      }

      if (worknovaTypes) {
        const selectedWoTypesFilter = filters.find(
          (filter) => filter.filter === "worknova_types"
        );

        setWoTypesFilters(selectedWoTypesFilter?.values || []);
      }

      if (users.length) {
        const selectedManagedFilter = filters.find(
          (filter) => filter.filter === "managed"
        );
        if (selectedManagedFilter) {
          const selectedValue = selectedManagedFilter.values.map(
            (managed) => managed.value
          );
          const findUsers = users.filter((user) =>
            selectedValue.includes(user.id)
          );
          setManagedFilters(findUsers);
        } else {
          setManagedFilters([]);
        }
        const selectedAssignedFilter = filters.find(
          (filter) => filter.filter === "assigned"
        );
        if (selectedAssignedFilter) {
          const selectedValue = selectedAssignedFilter.values.map(
            (assigned) => assigned.value
          );
          const findUsers = users.filter((user) =>
            selectedValue.includes(user.id)
          );
          setAssignedFilters(findUsers);
        } else {
          setAssignedFilters([]);
        }
      }
      if (workTicketTypes) {
        const selectedWorkticketTypeFilter = filters.find(
          (filter) => filter.filter === "workticket_type"
        );
        setWorkTicketTypeFilter(selectedWorkticketTypeFilter?.values || []);
      }
    }
  }, [
    anchorEl,
    filters,
    jobs,
    zips,
    cities,
    sites,
    customers,
    projects,
    users,
    directors,
    managers,
    serviceCategories,
    externalCategories,
    externalPriorities,
    externalStatuses,
    woFiles,
    currentTab,
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (subStatusFilters.length) {
      filterData.push({
        filter: "sub_status",
        group: "Sub Status",
        values: [...subStatusFilters],
      });
    }

    if (cancellationReasonFilters.length) {
      filterData.push({
        filter: "cancellation_reason",
        group: "Cancellation Reason",
        values: [...cancellationReasonFilters],
      });
    }

    if (customerFilters.length) {
      filterData.push({
        filter: "customer",
        group: "Customer",
        values: [...customerFilters],
      });
    }

    if (managerFilters.length) {
      filterData.push({
        filter: "manager",
        group: "Manager",
        values: [...managerFilters],
      });
    }

    if (directorFilters.length) {
      filterData.push({
        filter: "director",
        group: "Director",
        values: [...directorFilters],
      });
    }

    // if (seniorDirectorFilters.length) {
    //   filterData.push({
    //     filter: "senior_director",
    //     group: "Senior Director",
    //     values: [...seniorDirectorFilters],
    //   });
    // }

    if (stateFilters.length) {
      filterData.push({
        filter: "state",
        group: "State",
        values: [...stateFilters],
      });
    }

    if (zipFilters.length) {
      filterData.push({
        filter: "zip",
        group: "Zip",
        values: [...zipFilters],
      });
    }

    if (cityFilters.length) {
      filterData.push({
        filter: "city",
        group: "City",
        values: [...cityFilters],
      });
    }

    if (sitesFilters.length) {
      filterData.push({
        filter: "job_site_number",
        group: "Site",
        values: [...sitesFilters],
      });
    }

    if (serviceCategoriesFilters.length) {
      filterData.push({
        filter: "service_category",
        group: "Service Categories",
        values: [...serviceCategoriesFilters],
      });
    }

    if (externalCategoryFilters.length) {
      filterData.push({
        filter: "external_category",
        group: "Category",
        values: [...externalCategoryFilters],
      });
    }

    if (externalPriorityFilters.length) {
      filterData.push({
        filter: "external_priority",
        group: "Priority",
        values: [...externalPriorityFilters],
      });
    }

    if (externalStatusFilters.length) {
      filterData.push({
        filter: "external_status",
        group: "Status",
        values: [...externalStatusFilters],
      });
    }

    if (externalTypeFilters.length) {
      filterData.push({
        filter: "external_type",
        group: "Type",
        values: [...externalTypeFilters],
      });
    }

    if (woSourcesFilters.length) {
      filterData.push({
        filter: "sources",
        group: "Source",
        values: [...woSourcesFilters],
      });
    }

    if (levelComplianceFilters.length) {
      filterData.push({
        filter: "level_compliance",
        group: "Level of Compliance",
        values: [...levelComplianceFilters],
      });
    }

    if (completionMethodFilters.length) {
      filterData.push({
        filter: "completion_method",
        group: "Method of Completion",
        values: [...completionMethodFilters],
      });
    }

    if (projectFilters.length) {
      filterData.push({
        filter: "project",
        group: "Project",
        values: [...projectFilters],
      });
    }

    if (managedFilters.length) {
      const managedFilterConvert = managedFilters.map((managed) => {
        return {
          label: `${managed.first_name} ${managed.last_name}`,
          value: managed.id,
        };
      });
      filterData.push({
        filter: "managed",
        group: "Managed By",
        values: [...managedFilterConvert],
      });
    }

    if (assignedFilters.length) {
      const assignedFilterConvert = assignedFilters.map((assigned) => {
        return {
          label: `${assigned.first_name} ${assigned.last_name}`,
          value: assigned.id,
        };
      });
      filterData.push({
        filter: "assigned",
        group: "Assigned To",
        values: [...assignedFilterConvert],
      });
    }

    if (jobFilters.length) {
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.id,
          id: job.id,
          job_type: job?.job_type,
          parent_job_id: job?.parent_job_id,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }

    if (woFilesFilters.length) {
      filterData.push({
        filter: "worknova",
        group: "Workorders File",
        values: [...woFilesFilters],
      });
    }

    if (woTypesFilters.length) {
      filterData.push({
        filter: "worknova_types",
        group: "Workorders Types",
        values: [...woTypesFilters],
      });
    }

    if (workTicketTypeFilter.length > 0) {
      filterData.push({
        filter: "workticket_type",
        group: "Workticket Type",
        values: [...workTicketTypeFilter],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    if (!includeChild) {
      setJobFilters(value);
      return;
    }

    let mode = "add";
    if (value.length < jobFilters.length) {
      mode = "remove";
    }

    if (mode === "add") {
      const jobCurrent = jobFilters.map((job) => job.id);
      const jobNew = value.find((job) => !jobCurrent.includes(job.id));
      let children = [];
      if (jobNew && Number(jobNew?.job_type) === 1) {
        children = jobs.filter(
          (job) => Number(job?.parent_job_id) === Number(jobNew?.id)
        );
      }
      setJobFilters([...value, ...children]);
      return;
    }

    if (mode === "remove") {
      const jobCurrent = value.map((job) => job.id);
      const jobRemove = jobFilters.find((job) => !jobCurrent.includes(job.id));
      let valueNew = value;
      if (jobRemove && Number(jobRemove?.job_type) === 1) {
        valueNew = value.filter(
          (job) => Number(job?.parent_job_id) !== Number(jobRemove?.id)
        );
      }
      setJobFilters(valueNew);
      return;
    }
  };

  const handleChangeCustomer = (event, value) => {
    setCustomerFilters(value);
  };

  const handleChangeState = (event, value) => {
    setStateFilters(value ?? []);
  };

  const handleChangeZip = (event, value) => {
    setZipFilters(value ?? []);
  };

  const handleChangeCity = (event, value) => {
    setCityFilters(value ?? []);
  };

  const handleChangeServiceCategories = (event, value) => {
    setServiceCategoriesFilters(value ?? []);
  };

  const handleChangeSites = (event, value) => {
    setSitesFilters(value ?? []);
  };

  const handleChangeExternalCategory = (event, value) => {
    setExternalCategoryFilters(value ?? []);
  };

  const handleChangeExternalPriority = (event, value) => {
    setExternalPriorityFilters(value ?? []);
  };

  const handleChangeExternalStatus = (event, value) => {
    setExternalStatusFilters(value ?? []);
  };

  const handleChangeExternalTypes = (event, value) => {
    setExternalTypeFilters(value ?? []);
  };

  const handleChangeProject = (event, value) => {
    setProjectFilters(value);
  };

  const handleChangeWoFiles = (event, value) => {
    setWoFilesFilters(value ?? []);
  };

  const handleChangeWoTypes = (event, value) => {
    setWoTypesFilters(value ?? []);
  };

  const handleChangeWoSources = (event, value) => {
    setWoSourcesFilters(value ?? []);
  };

  const handleChangeAssigned = (event, value) => {
    setAssignedFilters(value ?? []);
  };

  const handleChangeLevelCompliance = (event, value) => {
    setLevelComplianceFilters(value ?? []);
  };

  const handleChangeCompletionMethod = (event, value) => {
    setCompletionMethodFilters(value ?? []);
  };

  const handleChangeSubStatus = (event, value) => {
    setSubStatusFilters(value ?? []);
  };

  const handleChangeCancellationReason = (event, value) => {
    setCancellationReasonFilters(value ?? []);
  };

  // const handleChangeSeniorDirector = (event, value) => {
  //   setSeniorDirectorFilters(value);
  // };

  const handleChangeDirector = (event, value) => {
    setDirectorFilters(value);
  };

  const handleChangeManager = (event, value) => {
    setManagerFilters(value);
  };

  const handleWorkTicketTypeClick = (type) => {
    setWorkTicketTypeFilter([type]);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          {(addOnFilters[currentTab] ?? []).length > 0 ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Sub Status
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={1}>
                  <Grid item md={12} className={classes.wrapperTeamMember}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={addOnFilters[currentTab] ?? []}
                      name="sub_status"
                      handleChange={handleChangeSubStatus}
                      multiple={true}
                      value={subStatusFilters}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {currentTab === 3 ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Cancellation Reason
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={1}>
                  <Grid item md={12} className={classes.wrapperTeamMember}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={workticketsCancellationReason}
                      name="cancellation_reason"
                      handleChange={handleChangeCancellationReason}
                      multiple={true}
                      value={cancellationReasonFilters}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {!isPartner ? (
            <>
              {/* <Grid container spacing={1} className={classes.filterGroup}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h4"
                    className={classes.titleFilter}
                    gutterBottom
                  >
                    Senior Director
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.wrapperTeamMember}
                    >
                      {seniorDirectors ? (
                        <FormSelectAuto
                          options={seniorDirectors}
                          name="senior_director"
                          handleChange={handleChangeSeniorDirector}
                          multiple={true}
                          value={seniorDirectorFilters}
                        />
                      ) : (
                        <LinearProgress color="secondary" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid container spacing={1} className={classes.filterGroup}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h4"
                    className={classes.titleFilter}
                    gutterBottom
                  >
                    Director
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.wrapperTeamMember}
                    >
                      {directors ? (
                        <FormSelectAuto
                          options={directors}
                          name="director"
                          handleChange={handleChangeDirector}
                          multiple={true}
                          value={directorFilters}
                        />
                      ) : (
                        <LinearProgress color="secondary" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1} className={classes.filterGroup}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h4"
                    className={classes.titleFilter}
                    gutterBottom
                  >
                    Manager
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.wrapperTeamMember}
                    >
                      {managers ? (
                        <FormSelectAuto
                          options={managers}
                          name="manager"
                          handleChange={handleChangeManager}
                          multiple={true}
                          value={managerFilters}
                        />
                      ) : (
                        <LinearProgress color="secondary" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Assigned To
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={usersList}
                    name="assigned"
                    handleChange={handleChangeAssigned}
                    multiple={true}
                    value={assignedFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Workticket Type
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                {workTicketTypes.map((type) => (
                  <Grid item xs={6} key={type.value}>
                    <Button
                      onClick={() => handleWorkTicketTypeClick(type)}
                      className={
                        workTicketTypeFilter[0]?.value === type.value
                          ? classes.activeButton
                          : classes.inactiveButton
                      }
                      startIcon={
                        type.value === 2 ? (
                          <ServiceIcon width={20} height={20} />
                        ) : (
                          <VisualInspectionIcon width={20} height={20} />
                        )
                      }
                      style={{ minWidth: 125 }}
                    >
                      {type.label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Level of Compliance
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={levelCompliance}
                    name="level_compliance"
                    handleChange={handleChangeLevelCompliance}
                    multiple={true}
                    value={levelComplianceFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Method of completion
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={completionMethod}
                    name="completion_method"
                    handleChange={handleChangeCompletionMethod}
                    multiple={true}
                    value={completionMethodFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!removeCustomers && !Boolean(jobFilters.length) && (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Customer
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                {customers && Boolean(customers.length) ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={customers}
                    name="customer"
                    handleChange={handleChangeCustomer}
                    multiple={true}
                    value={customerFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          )}
          {!removeProjects &&
          hasPermission(permissionProject.MODULE, permissions) ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Project
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                {projects !== null ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={projects}
                    name="project"
                    handleChange={handleChangeProject}
                    multiple={true}
                    value={projectFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          ) : null}
          {!removeJobs && !Boolean(customerFilters.length) ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Job
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                {Boolean(jobs) ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="job_number"
                    label="Job"
                    handleChange={handleChangeJob}
                    multiple={true}
                    value={jobFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          ) : null}
          {!removeJobs && sites?.length > 0 ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Sites
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={sites}
                  name="site_numbers"
                  handleChange={handleChangeSites}
                  multiple={true}
                  value={sitesFilters}
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                State
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={countryStates}
                name="state"
                handleChange={handleChangeState}
                multiple={true}
                value={stateFilters}
              />
            </Grid>
          </Grid>
          {zips?.length > 0 ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Zip Code
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={zips}
                  name="zip"
                  handleChange={handleChangeZip}
                  multiple={true}
                  value={zipFilters}
                />
              </Grid>
            </Grid>
          ) : null}
          {cities?.length > 0 ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  City
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={cities}
                  name="city"
                  handleChange={handleChangeCity}
                  multiple={true}
                  value={cityFilters}
                />
              </Grid>
            </Grid>
          ) : null}

          {serviceCategories?.length > 0 ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Service Categories
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={serviceCategories}
                  name="service_categories"
                  handleChange={handleChangeServiceCategories}
                  multiple={true}
                  value={serviceCategoriesFilters}
                />
              </Grid>
            </Grid>
          ) : null}

          {hasPermission(
            permissionWorkticket.WALGREENS_FILTERS,
            permissions
          ) ? (
            <>
              {externalCategories?.length > 0 ? (
                <Grid container spacing={1} className={classes.filterGroup}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="h4"
                      className={classes.titleFilter}
                      gutterBottom
                    >
                      Categories
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.autoContainerFilter}
                  >
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={externalCategories}
                      name="external_categories"
                      handleChange={handleChangeExternalCategory}
                      multiple={true}
                      value={externalCategoryFilters}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {externalPriorities?.length > 0 ? (
                <Grid container spacing={1} className={classes.filterGroup}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="h4"
                      className={classes.titleFilter}
                      gutterBottom
                    >
                      Priority
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.autoContainerFilter}
                  >
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={externalPriorities}
                      name="external_priorities"
                      handleChange={handleChangeExternalPriority}
                      multiple={true}
                      value={externalPriorityFilters}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {externalStatuses?.length > 0 && !isPartner ? (
                <Grid container spacing={1} className={classes.filterGroup}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="h4"
                      className={classes.titleFilter}
                      gutterBottom
                    >
                      External Status
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.autoContainerFilter}
                  >
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={externalStatuses}
                      name="external_statuses"
                      handleChange={handleChangeExternalStatus}
                      multiple={true}
                      value={externalStatusFilters}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {externalTypes?.length > 0 ? (
                <Grid container spacing={1} className={classes.filterGroup}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="h4"
                      className={classes.titleFilter}
                      gutterBottom
                    >
                      Types
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.autoContainerFilter}
                  >
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={externalTypes}
                      name="external_types"
                      handleChange={handleChangeExternalTypes}
                      multiple={true}
                      value={externalTypeFilters}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}

          {hasPermission(permissionWorkticket.WORKTICKET_WOID, permissions) ? (
            <>
              <Grid container spacing={1} className={classes.filterGroup}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h4"
                    className={classes.titleFilter}
                    gutterBottom
                  >
                    WO Upload
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.wrapperTeamMember}>
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={woFiles}
                        name="wo_files"
                        multiple={true}
                        handleChange={handleChangeWoFiles}
                        value={woFilesFilters}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1} className={classes.filterGroup}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h4"
                    className={classes.titleFilter}
                    gutterBottom
                  >
                    WO Type
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.wrapperTeamMember}>
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={worknovaTypes}
                        name="wo_types"
                        multiple={true}
                        handleChange={handleChangeWoTypes}
                        value={woTypesFilters}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
          {hasPermission(permissionWorkticket.REQUESTS_SOURCE, permissions) ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Source
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.wrapperTeamMember}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={sourceTypes}
                      name="source"
                      multiple={true}
                      handleChange={handleChangeWoSources}
                      value={woSourcesFilters}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {/* <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Wortiket Type
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.wrapperTeamMember}>
                  <BadgeComponent
                    icon={<VisualInspectionIcon width={12} height={16} />}
                    label="test"
                    customClass={classes.badgeFilter}
                  ></BadgeComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default WorkticketFilters;
