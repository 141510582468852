import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import Skeleton from "@material-ui/lab/Skeleton";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
// import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import useStyles from "./styles";
import AnalysisCardHistory from "./analysisCardHistory";
import DialogUpdateAnalysis from "./dialog/dialogUpdateAnalysis";
import useLunaLensStore from "store/lunaLensAnalysis";
import { getAnalysisReport } from "services/lunaLensService";
import GlobalUiContext from "contexts/globalUiContext";
import {
  permissionWorkticket,
  permissionSurface,
  hasPermission,
} from "lib/permissions";
import LensCommentsSurface from "./lensCommentsSurface";
import LensComments from "./lensComments";

const HistoryAnalysisDetail = ({
  categories,
  indexAnalysis,
  closeLensDialog,
  analysis,
  workticketId,
  workticketNumber,
}) => {
  const classes = useStyles();

  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [openSaveAnalysisDialog, setOpenSaveAnalysisDialog] = useState(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [userId, setUserId] = useState();
  const [userAnalysisId, setUserAnalysisId] = useState();
  const [value, setValue] = useState("");

  const setAnalysisHistory = useLunaLensStore(
    (state) => state.setAnalysisHistory
  );

  useEffect(() => {
    const getReport = async () => {
      if (analysis) {
        setLoadingAnalysis(true);
        const analysisData = await getAnalysisReport({
          id: analysis.analysisId,
        });
        const user = JSON.parse(localStorage.getItem("user"));
        setUserId(user.id);
        setUserAnalysisId(parseInt(analysisData.data.analysis.user_id));
        setAnalysisHistory(
          analysisData.data.analysis.luna_lens_workticket_analysis[0]
        );
        setLoadingAnalysis(false);
      }
    };
    getReport();
  }, [analysis, setAnalysisHistory]);

  return (
    <>
      <Box className={classes.historyContainer}>
        {loadingAnalysis ? (
          <Box className={classes.commentsContainerSkeletonDialog}>
            <Skeleton
              animation="wave"
              variant="rect"
              height={"90%"}
              style={{ marginTop: "24px" }}
            />
          </Box>
        ) : (
          <Box className={classes.editCommentsContainerDialog}>
            {hasPermission(permissionSurface.WORKTICKET, permissions) ? (
              <LensCommentsSurface
                workticketId={workticketId}
                workticketNumber={workticketNumber}
              />
            ) : (
              <LensComments
                workticketId={workticketId}
                workticketNumber={workticketNumber}
              />
            )}
          </Box>
        )}
        {loadingAnalysis ? (
          <Skeleton
            animation="wave"
            variant="rect"
            height={700}
            style={{ marginBottom: "24px" }}
          />
        ) : (
          <>
            <Box className={classes.historyDetailContainer}>
              <AnalysisCardHistory
                analysis={analysis}
                userId={userId}
                userAnalysisId={userAnalysisId}
              />
            </Box>
            <Box className={classes.historyBottomContainer}>
              <Box className={classes.historyButtonContainer}>
                {
                  userId === userAnalysisId ||
                  hasPermission(
                    permissionWorkticket.LUNA_LENS_MANAGE,
                    permissions
                  ) ? (
                    <Button
                      className={classes.buttonAnalysisFilled}
                      startIcon={<SaveOutlinedIcon />}
                      onClick={() => setOpenSaveAnalysisDialog(true)}
                    >
                      Save Changes
                    </Button>
                  ) : null
                  // <Button
                  //   className={classes.buttonAnalysisFilled}
                  //   startIcon={<PictureAsPdfIcon />}
                  //   onClick={() => {
                  //     console.log(analysis);
                  //   }}
                  // >
                  //   Print PDF
                  // </Button>
                }
              </Box>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="prompt"
                placeholder="Add something here to deepen insights..."
                styleOverride={{ backgroundColor: "#F8F8F8" }}
                endAdornment={
                  value.length > 0 && (
                    <IconButton
                      aria-label="close"
                      onClick={() => {}}
                      className={classes.sendButton}
                    >
                      <SendIcon className={classes.sendIcon} />
                    </IconButton>
                  )
                }
                value={value}
                onChange={(e) => setValue(e.target.value)}
                readonly={true}
              />
            </Box>
          </>
        )}
      </Box>
      <DialogUpdateAnalysis
        open={openSaveAnalysisDialog}
        handleCancel={() => {
          setOpenSaveAnalysisDialog(false);
        }}
        closeLensDialog={closeLensDialog}
        indexAnalysis={indexAnalysis}
        analysis={analysis}
      />
    </>
  );
};

export default HistoryAnalysisDetail;
