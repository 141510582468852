import React, { useContext, useEffect } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import { withRouter, Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import EditAnalysisTab from "../editAnalysisTab";
import NewAnalysisTab from "../newAnalysisTab";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { WorkticketViewProvider } from "contexts/workticketViewContext";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "../styles";

const LensAnalysisPage = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { history } = props;
  const { role, permissions } = globalUi;

  const { wtId, wtNumber, id, reportType } = props.match.params;

  useEffect(() => {
    if (
      role.category === "subcontractor" ||
      (!hasPermission(permissionWorkticket.LUNA_LENS_VIEW, permissions) &&
        !hasPermission(permissionWorkticket.LUNA_LENS_MANAGE, permissions))
    ) {
      history.push(`/dashboard`);
    }
  }, [role, permissions, history]);

  return (
    <WorkticketViewProvider>
      <PageHeader pageTitle="Luna Lens Analysis" lens={true}>
        <Link to={`/workticket/${wtId}`}>{wtNumber}</Link>
      </PageHeader>
      <Box className={classes.containerTab}>
        {reportType === "new" ? (
          <NewAnalysisTab id={id} />
        ) : (
          <EditAnalysisTab id={id} />
        )}
      </Box>
    </WorkticketViewProvider>
  );
};

export default withRouter(LensAnalysisPage);
