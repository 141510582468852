import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#747474",
  },
  dialogTitle: {
    color: "#4D4D4D",
    fontSize: 16,
    fontWeight: "600",
    wordWrap: "break-word",
  },
  filterTitle: {
    color: "#747474",
    fontSize: 12,
    fontWeight: "600",
    wordWrap: "break-word",
  },
  filterTitleAnalysis: {
    color: "#747474",
    textAlign: "center",
  },
  filterTitleAnalysisSubTitle: {
    color: "#747474",
    textAlign: "center",
    fontWeight: "bold",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    padding: "12px 12px 0px 12px",
  },
  titleContainerAnalysis: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "12px",
    gap: "4px",
  },
  titleContainerCategory: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "12px",
    gap: "4px",
  },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "40px",
    gap: "8px",
  },
  successContent: {
    display: "flex",
    padding: "0px 24px 12px 24px",
    justifyContent: "center",
    alignItems: "center",
  },
  filterGroup: {
    padding: "0 12px",
    marginBottom: 10,
    width: "100%",
    maxWidth: "410px",
    minWidth: "410px",
  },
  filterGroupCenter: {
    display: "flex",
    flexDirection: "column",
    padding: "0 12px",
    marginBottom: 10,
    width: "100%",
    maxWidth: "400px",
    minWidth: "400px",
    gap: "24px",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageDialogContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "0 12px",
    marginBottom: 10,
    gap: "24px",
  },
  imageDialogTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  filterGroupCenterCancel: {
    display: "flex",
    flexDirection: "column",
    padding: "0 12px",
    marginBottom: 10,
    width: "100%",
    maxWidth: "400px",
    minWidth: "400px",
  },
  wrapper: {
    width: "100%",
    maxWidth: "325px",
    minWidth: "325px",
  },
  textArea: {
    borderRadius: "4px",
    resize: "none",
    outline: "none",
    width: "100%",
    padding: "12px",
    marginTop: "10px",
    border: "1px solid #D9D9D9",
  },
  buttonConfirmSuccess: {
    textTransform: "Capitalize",
    backgroundColor: "#4F98BC",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    marginBottom: 24,
    marginRight: 24,
    marginLeft: 24,
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonConfirmVariant: {
    textTransform: "Capitalize",
    backgroundColor: "#4F98BC",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    marginBottom: 24,
    marginRight: 24,
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  headerBarDialog: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EDF5F8",
    padding: "4px 32px",
  },
  headerBarDialogTitle: {
    color: "#17457A",
    fontSize: "16px",
    fontWeight: "400",
    wordWrap: "break-word",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      alignSelf: "center",
    },
  },
  headerBarDialogTitleContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "center",
  },
  BodyDialogContainer: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "96%",
    },
    height: "100%",
    margin: "0 auto",
    paddingTop: "60px",
  },
  buttonCancelVariant: {
    textTransform: "Capitalize",
    color: "#4F98BC",
    border: "1px solid #4F98BC",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    marginBottom: 24,
    "&:hover": {
      border: "1px solid #4488A3",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "150px",
  },
  buttonCancelVariantAnalysis: {
    textTransform: "Capitalize",
    color: "#4F98BC",
    border: "1px solid #4F98BC",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    marginBottom: 24,
    marginLeft: 24,
    "&:hover": {
      border: "1px solid #4488A3",
    },
  },
  buttonConfirmVariantAnalysis: {
    textTransform: "Capitalize",
    backgroundColor: "#4F98BC",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    marginBottom: 24,
    marginRight: 24,
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
}));

export default useStyles;
