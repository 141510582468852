import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import CategoryHeader from "./categoryHeader";
import { Button } from "@material-ui/core";
import DialogFeedbackLuna from "./dialog/dialogFeedbackLuna";
import AnalysisList from "./analysisList";
import DialogSaveAnalysis from "./dialog/dialogSaveAnalysis";
import useLunaLensStore from "store/lunaLensAnalysis";
import { saveFeedback } from "services/lunaLensService";
import DialogLoadingLuna from "./dialog/dialogLoadingLuna";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import useStyles from "./styles";
// import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AnalysisLunaChat from "./analysisLunaChat";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import FeedbackButton from "./feedbackButton";

const AnalysisCard = ({
  newAnalysisFlag,
  loading,
  analysisThread,
  closeLensDialog,
  handleCategoryContext,
  indexThread,
  totalThreads,
  onHandleSaveAnalysis,
  responseText,
  onUpdateTrade,
  loadingUpdateAnalysis,
  setLoading,
  onHandleThumbUp,
  onHandleThumbDown,
  fillUp,
  fillDown,
}) => {
  const classes = useStyles();
  const ref = useRef();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [openSaveAnalysisDialog, setOpenSaveAnalysisDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [indexCategoryThumbDown, setIndexCategoryThumbDown] = useState();
  const [openLoading, setOpenLoading] = useState(false);
  const setAnalysisFeedback = useLunaLensStore(
    (state) => state.setAnalysisFeedback
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  const handleThumbDownOpenDialog = (id, category, prompt, runId) => {
    setOpenFeedbackDialog(true);
    setIndexCategoryThumbDown({ id, category, prompt, runId });
  };

  const onHandleDone = async () => {
    await onUpdateTrade(analysisThread.id);
    setEdit(false);
  };

  const handleThumbUp = async (id, category, prompt, runId) => {
    setOpenLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    await saveFeedback({
      type: "positive",
      reason: null,
      comment: null,
      run_id: runId,
      session_id: null,
      workticket_analysis_id: id,
      category: category,
      feedback_date: new Date().toISOString(),
      feedback_score: 1,
      feedback_reasons: ["Luna response met or exceeded my expectations."],
      prompt: prompt,
      user_id: user.id,
      user_email: user.email,
    });
    setAnalysisFeedback(id, category, {
      type: "positive",
      reason: null,
      comment: null,
    });
    setOpenLoading(false);
  };

  return (
    <Box ref={ref}>
      <Box className={classes.analysisCardContainer}>
        <Box className={classes.chatLensIconContainer}>
          <AnalysisLunaChat />
        </Box>
        <Box className={classes.chatLensMessageContainer}>
          <Typography variant="body1" className={classes.analysisHeaderTitle}>
            {responseText}
          </Typography>
          {parseInt(analysisThread.saved) === 1 && (
            <Typography variant="h5">(Saved)</Typography>
          )}
        </Box>
        {analysisThread.luna_lens_analysis_categories
          ?.filter(
            (category) =>
              category.luna_lens_analysis_categories_trade &&
              category.luna_lens_analysis_categories_trade.length > 0
          )
          .map((category, index) => (
            <React.Fragment key={index}>
              <CategoryHeader
                iconType={category.luna_lens_category.icon}
                tagColor={category.luna_lens_category.icon_color}
                tagBackgroundColor={
                  category.luna_lens_category.background_color
                }
                title={category.luna_lens_category.label}
                backGroundHeader={newAnalysisFlag ? "white" : "#F8F8F8"}
                feedback={newAnalysisFlag}
                fillUp={category?.feedback?.type === "positive"}
                fillDown={category?.feedback?.type === "negative"}
                onHandleThumbUp={() =>
                  handleThumbUp(
                    analysisThread.id,
                    category.luna_lens_category.value,
                    analysisThread.prompt,
                    analysisThread.run_id
                  )
                }
                onHandleThumbDown={() =>
                  handleThumbDownOpenDialog(
                    analysisThread.id,
                    category.luna_lens_category.value,
                    analysisThread.prompt,
                    analysisThread.run_id
                  )
                }
                edit={edit}
                categoryValue={category.luna_lens_category.value}
                loading={loading}
                handleCategoryContext={handleCategoryContext}
                indexThread={indexThread}
                totalThreads={totalThreads}
              />
              <AnalysisList
                analysis={category?.luna_lens_analysis_categories_trade}
                background={newAnalysisFlag}
                edit={edit}
                thread={analysisThread.id}
                category={category.luna_lens_category.value}
              />
            </React.Fragment>
          ))}
        <DialogFeedbackLuna
          open={openFeedbackDialog}
          handleCancel={handleCloseFeedbackDialog}
          thread={indexCategoryThumbDown}
        />
        <DialogLoadingLuna open={openLoading} handleCancel={setOpenLoading} />
        <DialogSaveAnalysis
          open={openSaveAnalysisDialog}
          handleCancel={() => {
            setOpenSaveAnalysisDialog(false);
          }}
          prompt={prompt}
          thread={analysisThread}
          onHandleDone={onHandleSaveAnalysis}
        />
      </Box>
      {!loading && (
        <Box className={classes.historyButtonContainer}>
          {!edit ? (
            <>
              <Button
                className={classes.buttonAnalysisFilled}
                startIcon={<EditIcon />}
                onClick={() => {
                  setEdit(true);
                  setLoading(true);
                }}
              >
                Edit Answer
              </Button>
              {parseInt(analysisThread.saved) === 0 && (
                <Button
                  className={classes.buttonHeaderAnalysis}
                  startIcon={<SaveOutlinedIcon style={{ color: "#4F98BC" }} />}
                  onClick={() => {
                    setOpenSaveAnalysisDialog(true);
                  }}
                >
                  Save Report
                </Button>
              )}
              {/* <Button
                className={classes.buttonHeaderAnalysis}
                startIcon={<PictureAsPdfIcon style={{ color: "#4F98BC" }} />}
                onClick={() => {
                  console.log(analysisThread);
                }}
              >
                Print PDF
              </Button> */}
              <Box style={{ display: "flex", margin: "0 24px", gap: "8px" }}>
                <FeedbackButton
                  type="up"
                  filled={fillUp}
                  onHandleClick={onHandleThumbUp}
                />
                <FeedbackButton
                  type="down"
                  filled={fillDown}
                  onHandleClick={onHandleThumbDown}
                />
              </Box>
            </>
          ) : loadingUpdateAnalysis ? (
            <Box className={classes.loadingContainer}>
              <LoadingStateHorizontal isVisible />
            </Box>
          ) : (
            <Button
              className={classes.buttonAnalysisFilled}
              startIcon={<EditIcon />}
              onClick={onHandleDone}
            >
              Done
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AnalysisCard;
