import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import ImageGallery from "./gallery/imageGallery";

const AnalysisList = ({ analysis, background, edit, thread, category }) => {
  const classes = useStyles();
  const updateDescriptionEditThread = useLunaLensStore(
    (state) => state.updateDescriptionEditThread
  );

  const addAnalysisTrade = useLunaLensStore((state) => state.addAnalysisTrade);

  const onHandleAddTrade = () => {
    addAnalysisTrade(thread, category, null, "");
  };

  return (
    <Box
      className={classes.listContainer}
      style={{ backgroundColor: background ? "#F8F8F8" : "white" }}
    >
      <List>
        {analysis.map((a, index) =>
          !edit ? (
            <ListItem key={index}>
              <Box>
                <Box className={classes.listItemContainer}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <FiberManualRecordIcon className={classes.listRecordIcon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {a.title && (
                          <Typography
                            component="span"
                            variant="body1"
                            className={classes.listSpan}
                          >
                            {`${a.title}: `}
                          </Typography>
                        )}
                        <Typography
                          component="span"
                          variant="body1"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {a?.description}
                        </Typography>
                      </>
                    }
                  />
                </Box>
                <Box style={{ margin: "12px 0px" }}>
                  <ImageGallery
                    imageUrls={a.luna_lens_trade_media.map((item) => ({
                      id: item?.file.id,
                      url: item?.file.file_url,
                      mime_type: 2,
                    }))}
                    numberImages={14}
                    viewOnly={true}
                    category={category.luna_lens_category?.value}
                    title={a.title}
                  />
                </Box>
              </Box>
            </ListItem>
          ) : (
            <Box key={index}>
              {a.title && (
                <Typography
                  className={classes.listSpan}
                >{`${a.title}: `}</Typography>
              )}
              <TextField
                className={classes.textAreaCard}
                value={a.description}
                onChange={(e) =>
                  updateDescriptionEditThread(
                    thread,
                    category,
                    index,
                    e.target.value
                  )
                }
                placeholder="Write here..."
                multiline
                minRows={4}
                maxRows={4}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
          )
        )}
      </List>
      {edit && (
        <Button
          className={classes.buttonHeader}
          style={{ marginBottom: "10px" }}
          onClick={() => {
            onHandleAddTrade();
          }}
        >
          <AddIcon style={{ color: "#4F98BC" }} />
        </Button>
      )}
    </Box>
  );
};

export default AnalysisList;
