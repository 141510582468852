import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import CategoryTag from "./categoryTag";
import FeedbackButton from "./feedbackButton";
import DialogNewCategory from "./dialog/dialogNewCategory";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import CardOptions from "./cardOptions";
import DialogChangeCategory from "./dialog/dialogChangeCategory";
import DialogDeleteCategory from "./dialog/dialogDeleteCategory";
import DialogAddCategory from "./dialog/dialogAddCategory";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import useLunaLensStore from "store/lunaLensAnalysis";

const CategoryHeader = ({
  id,
  categoryId,
  iconType,
  title,
  backGroundHeader,
  feedback,
  fillUp,
  fillDown,
  tagColor,
  tagBackgroundColor,
  onHandleThumbUp,
  onHandleThumbDown,
  edit,
  categoryValue,
  deleted = false,
  action,
  indexAnalysis,
  loading,
  handleCategoryContext,
  indexThread,
  totalThreads,
  userId,
  userAnalysisId,
}) => {
  const classes = useStyles();

  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [selectedId, setSelectedId] = useState();
  const [changeCategoryId, setChangeCategoryId] = useState();
  const [openNewCategoryDialog, setOpenNewCategoryDialog] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const [openChangeCategoryDialog, setOpenChangeCategoryDialog] =
    useState(false);
  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] =
    useState(false);
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
  const analysisComment = useLunaLensStore((state) => state.analysisComment);

  const onHandleSelectedAnalysisCategory = (id) => {
    setSelectedId(id);
  };

  const onHandleAction = (data) => {
    if (data.value === "ADD_CATEGORY") {
      setOpenAddCategoryDialog(true);
    } else if (data.value === "NEW_CATEGORY") {
      setOpenNewCategoryDialog(true);
    } else if (data.value === "DELETE_CATEGORY") {
      setOpenDeleteCategoryDialog(true);
    } else {
      setChangeCategoryId(data.id);
      setOpenChangeCategoryDialog(true);
    }
  };

  return (
    <Box
      className={classes.categoryHeaderContainer}
      style={{
        backgroundColor: backGroundHeader,
        ...(categorySelected
          ? { border: "1px solid #4F98BC" }
          : { border: "1px solid white" }),
      }}
    >
      <CategoryTag
        background={false}
        iconType={iconType}
        backgroundColor={tagBackgroundColor}
        color={tagColor}
        text={title}
        deleted={deleted}
      />
      {feedback || action === "addCategory"
        ? !edit && action !== "addCategory"
          ? !loading && (
              <Box className={classes.buttonHeaderContainer}>
                {indexThread === totalThreads - 1 && (
                  <Button
                    className={
                      categorySelected
                        ? classes.buttonHeaderMoreContextFilled
                        : classes.buttonHeaderMoreContext
                    }
                    onClick={() => {
                      setCategorySelected(!categorySelected);
                      handleCategoryContext(categoryValue);
                    }}
                  >
                    Add More Context
                  </Button>
                )}
                <FeedbackButton
                  type="up"
                  filled={fillUp}
                  onHandleClick={onHandleThumbUp}
                />
                <FeedbackButton
                  type="down"
                  filled={fillDown}
                  onHandleClick={onHandleThumbDown}
                />
              </Box>
            )
          : null
        : !deleted &&
          !analysisComment &&
          (userId === userAnalysisId ||
            hasPermission(
              permissionWorkticket.LUNA_LENS_MANAGE,
              permissions
            )) && (
            <CardOptions
              id={id}
              categoryId={categoryId}
              onSelectedAnalysisCategory={onHandleSelectedAnalysisCategory}
              onActionSelected={onHandleAction}
            />
          )}
      <DialogNewCategory
        open={openNewCategoryDialog}
        currentCategoryId={selectedId}
        handleCancel={() => {
          setOpenNewCategoryDialog(false);
        }}
      />
      <DialogChangeCategory
        open={openChangeCategoryDialog}
        currentCategoryId={selectedId}
        newCategoryId={changeCategoryId}
        handleCancel={() => {
          setOpenChangeCategoryDialog(false);
        }}
      />
      <DialogDeleteCategory
        open={openDeleteCategoryDialog}
        currentCategoryId={selectedId}
        handleCancel={() => {
          setOpenDeleteCategoryDialog(false);
        }}
      />
      <DialogAddCategory
        open={openAddCategoryDialog}
        currentCategoryId={selectedId}
        indexAnalysis={indexAnalysis}
        handleCancel={() => {
          setOpenAddCategoryDialog(false);
        }}
      />
    </Box>
  );
};

export default CategoryHeader;
