import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  TrashIcon,
  ChatIconOutline,
} from "components/common/LunaLens/images/images";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";

import useStyles from "./styles";
// import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const HistoryOptionsItems = [
  {
    id: 1,
    label: "View Report",
    value: "VIEW_REPORT",
    Icon: VisibilityOutlinedIcon,
  },
  {
    id: 2,
    label: "Edit Report",
    value: "EDIT_REPORT",
    Icon: EditIcon,
  },
  {
    id: 3,
    label: "Add Comment",
    value: "ADD_COMMENT",
    Icon: ChatIconOutline,
  },
  // {
  //   id: 4,
  //   label: "Print PDF",
  //   value: "PRINT_PDF",
  //   Icon: PictureAsPdfIcon,
  // },
  {
    id: 5,
    label: "Delete",
    value: "DELETE_ANALYSIS",
    Icon: TrashIcon,
  },
];

const HistoryOptions = ({
  onOptionClick,
  onCardClick,
  data,
  status,
  userId,
  analysisUserId,
}) => {
  const classes = useStyles();

  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [hoveredItem, setHoveredItem] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);

  const handleClick = (event) => {
    onCardClick(data);
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleMenuItemClick = (e) => {
    setMenuPosition(null);
    setHoveredItem(null);
    const value = e.currentTarget.getAttribute("data");
    onOptionClick(value);
  };

  const handleClose = () => {
    setMenuPosition(null);
  };

  return (
    <>
      <Button
        className={classes.buttonHorizontal}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon className={classes.moreIcon} fontSize="medium" />
      </Button>
      <Menu
        id="simple-menu"
        open={!!menuPosition}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 250,
          },
        }}
      >
        {HistoryOptionsItems?.filter((item) => {
          if (item.value === "DELETE_ANALYSIS") {
            return (
              userId === analysisUserId ||
              hasPermission(permissionWorkticket.LUNA_LENS_MANAGE, permissions)
            );
          }
          return item;
        })
          .map((item) => {
            if (
              item.value === "EDIT_REPORT" &&
              userId !== analysisUserId &&
              !hasPermission(permissionWorkticket.LUNA_LENS_MANAGE, permissions)
            ) {
              return {
                ...item,
                label: "View Report",
                Icon: VisibilityOutlinedIcon,
              };
            }
            return item;
          })
          .map((item) => {
            return (
              <MenuItem
                key={item.id}
                data={item.value}
                onMouseEnter={() => setHoveredItem(item.id)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={(e) => handleMenuItemClick(e)}
              >
                <Box
                  className={classes.menuIconBackground}
                  style={{
                    background: hoveredItem === item.id ? "#EDF5F8" : "#ECECEC",
                  }}
                >
                  <item.Icon
                    fontSize="inherit"
                    color={hoveredItem === item.id ? "#4F98BC" : "#747474"}
                    background={hoveredItem === item.id ? "#EDF5F8" : "#ECECEC"}
                    style={{
                      color: hoveredItem === item.id ? "#4F98BC" : "#747474",
                    }}
                  />
                </Box>
                <Typography
                  style={{
                    color: hoveredItem === item.id ? "#4F98BC" : "#747474",
                    marginLeft: "8px",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  {item.label}
                </Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default HistoryOptions;
