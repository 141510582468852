import React from "react";

const ServiceIcon = ({ width = 25, height = 33 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
  >
    <path
      d="M7.33789 10.0441C7.47646 10.2979 7.8222 10.2979 7.96008 10.0441L8.07012 9.84269L8.13465 9.72419C8.13465 9.72419 9.14605 7.87064 9.15081 7.86157C9.78047 6.7072 10.421 5.55492 11.0642 4.40612C11.3753 3.84985 11.8012 3.16949 11.8107 2.50378C11.8196 1.89383 11.5146 1.26018 11.0493 0.884447C10.5976 0.519871 10.0821 0.239643 9.52303 0.0660689C9.45171 0.0437622 9.37903 0.0228496 9.30635 0.00402832H12.8486C13.1624 0.00402832 13.4559 0.066766 13.7215 0.1783C14.9856 0.710176 15.6254 2.35948 14.8796 3.72716C14.8796 3.72716 12.6877 7.74446 12.651 7.81208C11.8923 9.20277 11.2436 10.8221 9.8219 11.6258C9.57737 11.7639 9.30907 11.8796 9.03058 11.8698C8.5979 11.8545 8.38461 11.4843 8.14959 11.1672C8.10612 11.1086 8.06333 11.0486 8.02121 10.9887C7.95601 10.896 7.88129 10.8151 7.82016 10.7189C7.80453 10.6945 7.79503 10.6799 7.78008 10.6555"
      fill="url(#paint0_linear_1_5451)"
    />
    <path
      d="M11.5569 3.48346C11.525 3.35519 11.4156 3.29734 11.2907 3.26527C10.987 3.1872 10.587 3.25272 10.2752 3.25272H6.63576C5.27251 3.25272 3.85967 3.13561 2.51272 3.37123C2.24034 3.41863 1.95913 3.48903 1.74653 3.67028C1.51966 3.86407 1.39875 4.1666 1.36955 4.46844C1.30773 5.1007 1.52374 5.89677 1.79068 6.4628C1.68472 6.05291 1.36547 5.61514 1.16849 5.23593C0.950449 4.81489 0.73241 4.39315 0.514371 3.97211C0.268483 3.4967 0.0164818 3.00038 0.000859042 2.46223C-0.0371789 1.1301 1.1943 0.00012207 2.44344 0.00012207H9.28687C9.36227 0.0189434 9.4363 0.0412501 9.51034 0.0621627C10.1094 0.229463 10.5747 0.515965 11.04 0.880541C11.6867 1.38732 11.9536 2.24613 11.7362 3.02896C11.7308 3.04848 11.7247 3.06799 11.7186 3.08821C11.6928 3.17186 11.6615 3.25412 11.6242 3.33498L11.6187 3.34822C11.6167 3.3531 11.614 3.35798 11.6113 3.36356"
      fill="url(#paint1_linear_1_5451)"
    />
    <path
      d="M11.3575 10.2C10.7638 11.0045 10.2428 11.8786 9.6872 12.7123L9.63898 12.7953C8.70773 14.4013 6.38062 14.4013 5.44937 12.7953L4.86997 11.7963L2.6563 7.97771L2.24671 7.27156C1.83305 6.55774 1.48324 5.87042 1.37456 5.03252C1.32905 4.6791 1.32633 4.30058 1.49818 3.99107C1.66052 3.6983 1.8935 3.5059 2.27592 3.4104C2.92324 3.24938 3.69215 3.22567 4.35374 3.22567C4.16016 3.2187 3.8871 3.24589 3.79336 3.4599C3.72068 3.6258 3.81374 3.81402 3.90476 3.97016C4.69065 5.32181 5.47518 6.67416 6.25903 8.0272C6.25903 8.0272 7.29761 9.81871 7.29829 9.8201C7.301 9.82498 7.30304 9.82986 7.30644 9.83474C7.32681 9.87308 7.34787 9.91142 7.36893 9.94906C7.46538 10.1212 7.56795 10.2892 7.67663 10.4524C7.9327 10.8399 8.18199 11.2498 8.58207 11.4994C8.92373 11.712 9.35641 11.7929 9.73203 11.6513C9.89573 11.5893 10.0431 11.4889 10.1837 11.383C10.6266 11.0491 11.0226 10.6496 11.3561 10.2007L11.3575 10.2Z"
      fill="url(#paint2_linear_1_5451)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_5451"
        x1="10.1289"
        y1="-1.82791"
        x2="12.1012"
        y2="11.3261"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stop-color="#80CEF1" />
        <stop offset="0.21" stop-color="#79C6EB" />
        <stop offset="0.35" stop-color="#68B0DA" />
        <stop offset="0.52" stop-color="#4D8EC0" />
        <stop offset="0.72" stop-color="#265E9B" />
        <stop offset="0.78" stop-color="#194D8E" />
        <stop offset="1" stop-color="#0D152E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_5451"
        x1="0.0490857"
        y1="3.4744"
        x2="11.8334"
        y2="2.11861"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stop-color="#6EC4EB" />
        <stop offset="0.2" stop-color="#65BEE8" />
        <stop offset="0.36" stop-color="#4CB1E1" />
        <stop offset="0.55" stop-color="#299DD7" />
        <stop offset="0.62" stop-color="#2691CB" />
        <stop offset="0.74" stop-color="#2072AC" />
        <stop offset="0.86" stop-color="#194E88" />
        <stop offset="1" stop-color="#0D152E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_5451"
        x1="9.40532"
        y1="13.2721"
        x2="2.20835"
        y2="1.36169"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stop-color="#D5DD28" />
        <stop offset="0.23" stop-color="#B8C828" />
        <stop offset="0.46" stop-color="#6E942A" />
        <stop offset="0.72" stop-color="#17562C" />
        <stop offset="0.75" stop-color="#174925" />
        <stop offset="0.82" stop-color="#173A1D" />
        <stop offset="0.89" stop-color="#173118" />
        <stop offset="1" stop-color="#172F17" />
      </linearGradient>
    </defs>
  </svg>
);

export default ServiceIcon;
